enum RouteNames {
  R_APP = 'app',
  R_APP_HOME = 'app.home',
  R_ERROR_401 = '401',
  R_ERROR_403 = '403',
  R_ERROR_404 = '404',
  R_ERROR_500 = '500',
  R_WELCOME = 'welcome',
  R_APP_SURVEYEE_ASSESSMENT = 'app.surveyee-assessment',
  R_APP_SURVEY_ROOT = 'app.survey-root',
  R_APP_ASSESSMENT = 'app.assessment',
  R_APP_EXTERNALS_REGISTRATION = 'app.external-expert-registration',
  R_APP_ASSESSMENT_COUPLES_FAVORITES = 'app.couples-favorites',
  R_APP_ASSESSMENT_COUPLES_EXPERTS = 'app.couples-experts',
  R_APP_ASSESSMENT_COUPLES = 'app.couples',
  R_APP_ASSESSMENT_REVIEW_EXPERTS = 'app.review-experts',
  R_APP_ASSESSMENT_REVIEW_EXPERT = 'app.review-expert',
  R_APP_REQUEST_HAS_SENT = 'app.request-has-sent',
  R_APP_ASK_FOR_ASSESSMENT = 'app.ask-for-assessment',
  R_APP_SURVEY_ANALYTICS = 'app.survey-analytics',
  R_APP_SURVEY_ANALYTICS_EXPORT = 'app.survey-analytics.export',
  R_APP_SURVEY_ANALYTICS_EXPORT_V2 = 'app.survey-analytics.export.v2',
  R_APP_SURVEY_ANALYTICS_EXPORT_GPT = 'app.survey-analytics.export.gpt',
  R_APP_GPT_ANALYTICS = 'app.gpt-survey-analytics',
  R_APP_USER_ANALYTICS = 'app.user-analytics',
  R_APP_USER_ANALYTICS_ME = 'app.user-analytics.my-results',
  R_APP_USER_ANALYTICS_EMPLOYEE = 'app.user-analytics.employee-results',
  R_APP_USER_ANALYTICS_EMPLOYEE_PIVOT_TABLE = 'app.user-analytics.employee-results.pivot-table',
  R_APP_USER_ANALYTICS_EMPLOYEE_COMPETENCY_COMPARISON = 'app.user-analytics.employee-results.competency-comparison',
  R_APP_USER_ANALYTICS_EMPLOYEE_DASHBOARDS = 'app.user-analytics.employee-results.dashboards',
  R_APP_FEEDBACK_FROM_COLLEAGUES = 'app.feedback-from-colleagues',
  R_APP_COMMENTS_FEED = 'app.comments-feed',
  R_APP_SURVEY_STATISTICS = 'app.survey-statistics',
  R_APP_EXTERNAL_EXPERT_LETTER_VIEW = 'app.external-expert-letter-view',
}

export default RouteNames;
